
import Vue from 'vue';

import WalletsApi from 'Apis/Wallets';
import WalletAddressRequestData from 'Entities/walletExecutor/WalletAddressRequestData';
import CancelDepositRequestData from 'Entities/walletExecutor/CancelDepositRequestData';
import ApiError from 'Entities/ApiError';
import Transfer from 'Entities/privatePresenter/Transfer';
import EmptyResult from 'Entities/walletExecutor/EmptyResult';
import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import AssetValue from 'UI/AssetValue.vue';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';
import QrCode from 'UI/QrCode.vue';

type Data = {
    showQr: boolean;
    showWhitelistedWalletDetails: boolean;
    previousRoute: string;
    historyData: {
        assetSymbol: string;
        blockchainName: string;
        createdAt: string;
        id: string;
        quantity: number;
        sourceAddress: {
            memo: string;
            address: string;
        };
        destinationAddress: {
            memo: string;
            address: string;
        };
    };
};

type Methods = {
    toggleDetails: () => void;
    calculateQrTop: () => void;
    toggleQr: () => void;
    copy: (text: string, fieldName: string) => void;
    setModalData: (data: Transfer) => void;
    cancelTransferAndHideModal: (data: string) => void;
};

type Computed = {
    qrValue: string;
};

export default Vue.extend<Data, Methods, Computed>({
    components: {
        QrCode,
        Icon,
        Button,
        AssetValue,
    },
    data() {
        return {
            showQr: false,
            showWhitelistedWalletDetails: true,
            previousRoute: '/wallets',
            historyData: {
                assetSymbol: '',
                blockchainName: '',
                createdAt: '',
                id: '',
                quantity: 0,
                sourceAddress: {
                    memo: '',
                    address: '',
                },
                destinationAddress: {
                    memo: '',
                    address: '',
                },
            },
        };
    },
    computed: {
        qrValue() {
            if (!this.historyData.destinationAddress) {
                return '';
            }
            return this.historyData.destinationAddress.memo
                ? `address=${this.historyData.destinationAddress.address}?memo=${this.historyData.destinationAddress.memo}`
                : `address=${this.historyData.destinationAddress.address}`;
        },
    },
    methods: {
        toggleDetails() {
            this.showWhitelistedWalletDetails = !this.showWhitelistedWalletDetails;
            setTimeout(() => {
                this.calculateQrTop();
            }, 300);
        },
        calculateQrTop() {
            const topMargin = 72;
            const goBackHeight = 14;
            const goBackMargin = 15;
            const headerHeight = 54;

            const rects = (this.$refs.qrButton as any).getBoundingClientRect();
            this.$nextTick(() => {
                (this.$refs.qr as any).$el.style.top = `
                    ${rects.y
                - topMargin
                - goBackMargin
                - goBackHeight
                - headerHeight
                + (rects.height / 2)}px
                `;
            });
        },
        toggleQr() {
            this.calculateQrTop();
            this.showQr = !this.showQr;
        },
        copy(text, fieldName) {
            if (text && fieldName) {
                copyTextWithPermissions(String(text), this.$store.dispatch, fieldName);
            }
        },
        async setModalData(e) {
            try {
                const { data: res } = await WalletsApi.getAddress(new WalletAddressRequestData({
                    accountId: e.toAccountId ?? '',
                    assetSymbol: e.assetSymbol,
                    blockchainName: e.blockchainName ?? '',
                }));
                this.historyData.assetSymbol = e.assetSymbol;
                this.historyData.blockchainName = e.blockchainName ?? '';
                this.historyData.createdAt = e.createdAt;
                this.historyData.id = e.id;
                this.historyData.sourceAddress.memo = e.memo ?? '';
                this.historyData.sourceAddress.address = e.address ?? '';
                this.historyData.destinationAddress.memo = res instanceof EmptyResult ? '' : (res.memo ?? '');
                this.historyData.destinationAddress.address = res instanceof EmptyResult ? '' : res.address;
                this.historyData.quantity = e.quantity;
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during setting modal data');
                }
            }
        },
        async cancelTransferAndHideModal(id) {
            try {
                await WalletsApi.cancelDeposit(new CancelDepositRequestData({
                    transferId: id,
                }));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during cancelling deposit');
                }
            } finally {
                await this.$router.push(this.previousRoute);
            }
        },
    },
    async mounted() {
        await this.setModalData(new Transfer(this.$route.query as any));
        const { previousRoute } = this.$route.query;
        if (typeof previousRoute === 'string') {
            this.previousRoute = previousRoute;
        }
    },
});
